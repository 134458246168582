import React from 'react';
import { gql } from '@apollo/client';
import { getCookie } from 'cookies-next';
import { NextPage } from 'next';

import { PageContext } from 'lib/types/context';
import redirect from 'lib/redirect';

import {
  CurrentUserProjectsQuery,
  CurrentUserProjectsQuery_currentUser as CurrentUser,
} from './__generated__/CurrentUserProjectsQuery';

const getRedirectPath = (currentUser: CurrentUser | null | undefined) => {
  const [project] = currentUser?.projects || [];

  if (project) {
    const [projectBoard] = project.projectBoards;

    if (projectBoard?.board) {
      return `/${project.slug}/${projectBoard.board.slug}`;
    }

    return `/${project.slug}`;
  }

  return '';
};

const currentUserProjectsQuery = gql`
  query CurrentUserProjectsQuery {
    currentUser {
      id
      slug
      projects {
        id
        slug
        projectBoards {
          id
          board {
            id
            slug
          }
        }
      }
    }
  }
`;

const withIndexRedirect = (Page: NextPage) => {
  const WithIndexRedirect = (props: any) => <Page {...props} />;

  WithIndexRedirect.getInitialProps = async (context: PageContext) => {
    if (context.isLoggedIn) {
      const { data } = await context.apolloClient.query<CurrentUserProjectsQuery>({
        query: currentUserProjectsQuery,
        fetchPolicy: 'no-cache',
      });
      const lastVisitedBoardUrl = getCookie('lastVisitedBoardUrl', {
        req: context.req,
        res: context.res,
      });
      const redirectPath = lastVisitedBoardUrl || getRedirectPath(data?.currentUser);

      if (redirectPath) {
        redirect(context, redirectPath);
      } else {
        redirect(context, '/');
      }
    }

    let pageProps = {};

    if (Page.getInitialProps) {
      pageProps = await Page.getInitialProps(context);
    }

    return pageProps;
  };

  return WithIndexRedirect;
};

export default withIndexRedirect;
